:root {
  --main-bg-color: white;
  --off-white-color: #f6efdf;
  --turquoise-color: #86c3b6;
  --yellow-color: #efd06d;
  --red-color: #d44926;
  --dark-blue-color: #3b466c;
}

.material-symbols-outlined {
  position: relative;
  top: 5px;
}

body {
  font-family: "Lato", sans-serif;
  margin: 0px;
  background-color: var(--main-bg-color);
  position: relative;
}

.page-content {
  position: relative;
  min-height: calc(100vh - 70px);
  padding-bottom: 70px;
}

.footer {
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
  background-color: var(--dark-blue-color);
  text-align: center;
  color: white;
  max-height: 50px;
}

.footer a, .footer a:visited, .footer a:hover {
  color: var(--yellow-color);
}


a {
  color: var(--dark-blue-color);
}

a:hover {
  color: var(--turquoise-color);
}

a:visited{
  color: var(--red-color);
}

a:visited:hover{
  color: var(--turquoise-color);
}

.navbar {
  position: relative;
  background-color: var(--dark-blue-color);
  padding: 5px;
  color: white;
  height: 60px;
}

.navbar p {
  text-align: right;
  font-size: 1.1em;
  padding-right: 10px;
}

.navbar #home-link {
  border: none;
}

.navbar #home-link .text {
  font-size: 1.5em;
  position: relative;
  top: -10px;
  left: 5px;
}

.navbar a, .navbar a:visited, .navbar a:hover {
  color: white;
  text-decoration: none;
  border: white 1px solid;
  padding: 5px;
  margin-right: 10px;
}

#navbar-logo {
  width: 50px;
  position: relative;
  top: 5px;
}

.navbar .links {
  position: relative;
  top: -60px;
}

.navbar .links #sign-in-link {
  position: relative;
  top: 5px;
}

.current-user {
  padding-top: 25px;
  padding-bottom: 25px;
}

.current-user:hover .dropdown-content,
.dropdown-content:hover {
  display: block;
}

.dropdown-content {
  display: none;
  background-color: var(--dark-blue-color);
  position: absolute;
  top: 50px;
  right: 0px;
}

.dropdown-content a, .dropdown-content a:visited, .dropdown-content a:hover {
  color: white;
  padding: 8px;
  text-decoration: none;
  display: block;
  border: none;
}

.landing-page-content {
  display: flex;
  margin: 5px;
  flex-wrap: wrap;
}

.landing-page-content .left-content {
  padding-right: 10px;
  width: calc(50% - 10px);
}

.landing-page-content .right-content {
  position: flex;
  width: 50%;
}

.landing-page-content .bottom-left-content{
  padding-top: 10px;
  width: 60%;
}

.landing-page-content .bottom-right-content{
  padding: 10px;
  min-width: calc(40% -20px);
}


.instagram-media{
  width: 100%;
}

.landing-page-full-screen-content {
  margin: 5px;

}

#home-page-image {
  width: 100%;
}

.button-link, .button-link:visited {
  text-decoration: none;
  color: white;
  background-color: var(--red-color);
  padding: 10px;
  font-size: 1.5em;
  font-weight: bold;
}

.button-link:hover {
  background-color: var(--yellow-color);
  color: var(--dark-blue-color);
}

#sign-up-link {
  width: 300px;
  position: relative;
  left: calc(50% - 50px);
}

.content {
  margin: 5px;
}

#insta-1 {
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  margin: 1px;
  max-width: 540px;
  min-width: 326px;
  padding: 0;
  width: 99.375%;
  width: -webkit-calc(100% - 2px);
  width: calc(100% - 2px);
}

#insta-2 {
  padding: 16px;
}

#insta-3 {
  background: #ffffff;
  line-height: 0;
  padding: 0 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

#insta-4 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#insta-5 {
  background-color: #f4f4f4;
  border-radius: 50%;
  flex-grow: 0;
  height: 40px;
  margin-right: 14px;
  width: 40px;
}

#insta-6 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

#insta-7 {
  background-color: #f4f4f4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  margin-bottom: 6px;
  width: 100px;
}

#insta-8 {
  background-color: #f4f4f4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  width: 60px;
}

#insta-9 {
  padding: 19% 0;
}

#insta-10 {
  display: block;
  height: 50px;
  margin: 0 auto 12px;
  width: 50px;
}

#insta-11 {
  padding-top: 8px;
}

#insta-12 {
  color: #3897f0;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 550;
  line-height: 18px;
}

#insta-13 {
  padding: 12.5% 0;
}

#insta-14 {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  align-items: center;
}

#insta-15 {
  background-color: #f4f4f4;
  border-radius: 50%;
  height: 12.5px;
  width: 12.5px;
  transform: translateX(0px) translateY(7px);
}

#insta-16 {
  background-color: #f4f4f4;
  height: 12.5px;
  transform: rotate(-45deg) translateX(3px) translateY(1px);
  width: 12.5px;
  flex-grow: 0;
  margin-right: 14px;
  margin-left: 2px;
}

#insta-17 {
  background-color: #f4f4f4;
  border-radius: 50%;
  height: 12.5px;
  width: 12.5px;
  transform: translateX(9px) translateY(-18px);
}

#insta-18 {
  margin-left: 8px;
}

#insta-19 {
  background-color: #f4f4f4;
  border-radius: 50%;
  flex-grow: 0;
  height: 20px;
  width: 20px;
}

#insta-20 {
  width: 0;
  height: 0;
  border-top: 2px solid transparent;
  border-left: 6px solid #f4f4f4;
  border-bottom: 2px solid transparent;
  transform: translateX(16px) translateY(-4px) rotate(30deg);
}

#insta-21 {
  margin-left: auto;
}

#insta-22{
  width: 0px; border-top: 8px solid #F4F4F4; border-right: 8px solid transparent; transform: translateY(16px);
}

#insta-23{
  background-color: #F4F4F4; flex-grow: 0; height: 12px; width: 16px; transform: translateY(-4px);
}

#insta-24{
  width: 0; height: 0; border-top: 8px solid #F4F4F4; border-left: 8px solid transparent; transform: translateY(-4px) translateX(8px);
}

#insta-25{
  display: flex; flex-direction: column; flex-grow: 1; justify-content: center; margin-bottom: 24px;
}

#insta-26{
  background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; margin-bottom: 6px; width: 224px;
}

#insta-27{
  background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; width: 144px;
}

#insta-28{
  color:#c9c8cd; font-family:Arial,sans-serif; font-size:14px; line-height:17px; margin-bottom:0; margin-top:8px; overflow:hidden; padding:8px 0 7px; text-align:center; text-overflow:ellipsis; white-space:nowrap;
}

#insta-29{
  color:#c9c8cd; font-family:Arial,sans-serif; font-size:14px; font-style:normal; font-weight:normal; line-height:17px;
}

#insta-30{
  color:#c9c8cd; font-family:Arial,sans-serif; font-size:14px; font-style:normal; font-weight:normal; line-height:17px;
}

@media screen and (max-width: 650px) {
  .footer {
    padding-top: 10px;
    padding-bottom: 20px;
    position: absolute;
    bottom: 0;
  }

  .page-content {
    padding-bottom: 80px;
    min-height: calc(100vh - 80px);
    position: relative;
  }

  .landing-page-content {
    display: inline;
    position: relative;
    margin: 0px;
    float: none;
  }

  .landing-page-content .right-content {
    width: 100%;
    position: static;
  }

  .landing-page-content .left-content {
    width: 100%;
    position: static;
    padding-right: 0px;
    padding-bottom: 20px;
  }

  .landing-page-content .bottom-left-content {
    width: 100%;
    position: static;
  }

  .landing-page-content .right-content {
    padding: 0;
  }

  .navbar {
    height: 120px;
  }

  .navbar .links {
    top: 0px;
  }

  .content {
    margin: 0px;
  }

}

@media screen and (min-width: 1300px) {

  .landing-page-content .left-content{
    width: 30%;
  }
  
  .landing-page-content .right-content{
    width: 40%;
  }

  .landing-page-content .bottom-left-content{
    padding-top: 0;
    width: calc(30% - 10px);
  }

  
}